import styles from "./PublicChats.module.css"
import React, {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../redux/ThemeContext";
import { AppContext } from '../../redux/AppContext'; // import AppContext
import {setUseMaximize} from "../../redux/actions";
import {getApi} from "../../services/apiService";
import {useMsal} from "@azure/msal-react";
import MaxModal from "../MaxModal/MaxModal";
import CodeWithHighlighting from "../CodeWithHighlighting/CodeWithHighlighting";
import FullMarkdown from "../FullMarkdown/FullMarkdown";
import {useApi} from "../../hooks/useApi";

function PublicChats() {
    const { theme } = useContext(ThemeContext);
    const { state, dispatch } = useContext(AppContext);
    const { instance } = useMsal();
    const { isMobile, useMaximize } = state;
    const [publicList, setPublicList] = useState([])
    const [messages, setMessages] = useState(null)
    const [title, setTitle] = useState([])
    const [detailId, setDetailId] = useState(null);

    const api = useApi();

    useEffect(() => {
        const loadPublic = () => {
            api.get('/api/chats/search/public').then(response => {
                setPublicList(response.data);
            })
            .catch((error)=> { /*error handled in apiService*/})
        }
        loadPublic()
    }, [])


    const loadChatThread = (chatId) => {
        api.get(`/api/chats/${chatId}/thread-public`, )
            .then(response => {
                setMessages(response.data.entries);
                setTitle(response.data.title);
                setDetailId (chatId);
            })
            .catch((error) => { /*error handled in apiService*/
            });
    }

   const chatDetailDiv = messages && (
        <MaxModal show={true} handleClose={()=> setMessages(null)}>
            <div className={styles["chat-container"]}>
                {messages.map((d,index) => (<div className={`${styles["chat-entry"]} ${styles[d.role]} markdown`}>
                        <div className={`${styles["entry-role"]} code-view-in-chat`}>
                            {d.role == "system" && <img
                                src={theme == "dark" ? "/icons8-system-50--dark.png" : "/icons8-system-50--light.png"}
                            />}
                            {d.role == "user" && <img
                                src={theme == "dark" ? "/icons8-user-60-dark.png" : "/icons8-user-60-light.png"}
                            />}
                            {d.role == "assistant" && <img
                                src={
                                    d.meta?.company == "MISTRAL"? "/mistral.png":
                                        (d.meta?.company == "GEMINI"? "/gemini.png":  (theme == "dark" ? "/icons8-chatgpt-50-dark.png" : "/icons8-chatgpt-50-light.png"))}
                            />}

                            {/*{d.role == "assistant" && <img*/}
                            {/*    src={theme == "dark" ? "/icons8-chatgpt-50-dark.png" : "/icons8-chatgpt-50-light.png"}*/}
                            {/*/>}*/}

                        </div>
                        <div className={`${styles["entry-message"]} code-view-in-chat`}>
                        {d.role === "assistant" ? (
                            <div className={styles["markdown-parent"]}><FullMarkdown content={d.content}/></div>
                                    ) : (
                                    d.content
                        )}
                        {d.image_url && (
                            <div>
                                <a href={d.image_url} target="_blank"
                                   rel="noopener noreferrer">
                                    <img className={styles["image-in-thread"]}
                                         src={d.image_url}/>
                                </a>
                            </div>
                        )}
                        </div>
                        {/*<div className={`${styles["entry-message"]} code-view`}>{d.content}</div>*/}
                    </div>
                ))}
            </div>
        </MaxModal>
    )

    return (
        <>
            {publicList && <div>
                <h3>Public chats from your teammates</h3>
                <div className={`${styles["public-container"]}`}>
                    {publicList.map((d, index) => (<div className={styles["public-line"]}
                                                        onClick={() => loadChatThread(d.id)}
                                                        key={index}
                    >
                        <div className={styles["public-title"]}>{d.title}</div>
                        {d.view_count > 0 && <div className={styles["public-title-panel"]}>
                            <div className={styles["icon"]}>
                                <svg viewBox="0 0 24 24" aria-hidden="true">
                                    <g>
                                        <path
                                            d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"></path>
                                    </g>
                                </svg>
                                <div>{d.view_count}</div>
                            </div>
                        </div>}
                        {/*{detailId === d.id && detailDiv}*/}
                    </div>))}
                </div>
                {chatDetailDiv}
            </div>}
        </>
    );
}

export default PublicChats;

